import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { asnwerQuestion } from "../../redux/user";

function parse(obj) {
  try {
    return JSON.parse(JSON.stringify(obj || {}) || "{}");
  } catch (error) {
    return {};
  }
}

export default (props) => {
  const { id, answer, goNext } = props;
  const dispatch = useDispatch();
  const answerObj = parse(answer);
  const [value, setValue] = useState(answerObj);

  useEffect(() => {
    const answerObj = parse(props.answer);
    setValue(answerObj);
  }, [props]);
  const go = (value) => {
    dispatch(asnwerQuestion(id, value));
    goNext();
  };
  const change = (id, value) => {
    setValue((obj) => ({
      ...obj,
      [id]: value,
    }));
  };
  const social = ["Github", "Linkedin", "Outra"];
  const icons = ["/assets/img/github.png", "/assets/img/linkedin.png"];

  return (
    <div className="d-flex flex-column w-100 h-100 align-items-center justify-content-center">
      {social.map((s, index) => (
        <div className="social-input" key={s}>
          <div className="logo">
            {icons[index] && <img height="32px" src={icons[index]}></img>}
          </div>
          <input
            value={value[s] || ""}
            placeholder={s}
            onChange={(e) => change(s, e.target.value)}
          />
        </div>
      ))}

      <div
        className=" text-center button"
        onClick={() => go(value)}
        style={{
          cursor: "pointer",
          borderRadius: "8px",
          marginTop: "8px",
          padding: "16px",
          fontSize: "18px",
          textAlign: "center",
        }}
      >
        Continuar
      </div>
    </div>
  );
};
