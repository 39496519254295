import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import MaskedInput from "react-text-mask";
import { asnwerQuestion } from "../../redux/user";

export default (props) => {
  const { id, answer, goNext } = props;
  const dispatch = useDispatch();
  const [value, setValue] = useState(answer || "");

  useEffect(() => {
    setValue(props.answer || "");
  }, [props]);
  const minWidth = 3;
  const go = (value) => {
    if (value.length < minWidth) return;
    dispatch(asnwerQuestion(id, value));
    goNext();
  };

  return (
    <div className="d-flex flex-column w-100 h-100 align-items-center justify-content-center">
      <MaskedInput
        className="input-simple-text "
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            go(value);
          }
        }}
        autoFocus
        placeholderChar={"\u2000"}
        mask={(value, ...args) => {
          if (value.replaceAll("\u2000", "").length <= 14)
            return [
              "(",
              /[1-9]/,
              /\d/,
              ")",
              " ",
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              "-",
              /\d/,
              /\d/,
              /\d/,
              /\d/,
            ];
          return [
            "(",
            /[1-9]/,
            /\d/,
            ")",
            " ",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            "-",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
          ];
        }}
        type="text"
      />

      <div
        className=" text-center button"
        onClick={() => go(value)}
        style={{
          cursor: "pointer",
          borderRadius: "8px",
          marginTop: "8px",
          padding: "16px",
          fontSize: "18px",
          textAlign: "center",
          opacity: value.length < minWidth ? 0.5 : 1,
        }}
      >
        Continuar
      </div>
    </div>
  );
};
