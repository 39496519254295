import { TYPES } from "./types";

export default {
  start: {
    title: "Olá 😁",
    subtitle: "Vamos fazer sua inscrição no Zeero Beta Testers. É rapidinho!",
    img: "assets/img/initial-img.png",
  },
  finish: {
    title: "Obrigado!",
    subtitle:
      "Nosso time Zeero já recebeu suas informações e entraremos em contato em breve 🙂",
    img: "assets/img/finish.png",
  },
  questions: [
    {
      id: "nome",
      type: TYPES.SIMPLETEXT,
      title: "Vamos começar!",
      subtitle: "Qual seu nome completo?",
    },
    {
      id: "cidade",
      type: TYPES.SIMPLETEXT,
      title: "🏘️",
      subtitle: "Qual sua cidade e estado?",
    },
    {
      id: "email",
      type: TYPES.SIMPLETEXT,
      title: "✉️",
      subtitle: "Qual seu e-mail? ",
    },
    {
      id: "celular",
      type: TYPES.PHONENUMBER,
      title: "📱",
      subtitle: "Qual seu celular?",
    },
    {
      id: "experiencia",
      type: TYPES.SIMPLETEXT,
      title: "Experiência atual",
      subtitle: "Em qual mercado você atua e qual sua ocupação atual?",
    },
    {
      id: "empresa",
      type: TYPES.SIMPLETEXT,
      title: "Sua empresa",
      subtitle: "De qual empresa você faz parte (ou dono / autônomo)?",
    },
    {
      id: "plataforma",
      type: TYPES.MULTOPTIONS,
      title: "👨🏻‍💻",
      subtitle: "Qual das plataformas abaixo você mais utiliza atualmente?",

      options: [
        "Google Meet",
        "Zoom",
        "Microsoft Teams",
        "Skype",
        "Whereby",
        "Outra",
      ],
    },
    {
      id: "fim",
      type: TYPES.SIMPLETEXT,
      title: "Por fim…",
      subtitle:
        "Descreva em uma ou poucas palavras o maior problema que você encontra utilizando essas plataformas.",
      btnText: "Finalizar",
    },
  ],

  onFinish: (user) => {
    const columns = [
      "nome",
      "cidade",
      "email",
      "celular",
      "experiencia",
      "empresa",
      "plataforma",
    ];
    let _user = {
      ...user,
      ...(user && user.questions ? user.questions : {}),
    };
    let text = "";
    text += "**NOVA INSCRIÇÃO**\n\n\n";
    text += columns.map((c) => `**${c}**: ${_user[c]}`).join("\n");

    fetch(
      "https://discord.com/api/webhooks/823555667104890910/2SL4iZP3ns-uyCtaAI-L43XMkNEmGpk6UchkJqsfNgCDClwAanYB_jhM3VCIcd4tVFQO",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: "Beta Testers",
          avatar_url:
            "https://cdn.discordapp.com/icons/802270237302194206/59e2c4ad325ffbec5d6fc8385f0ecad1.webp",
          content: text,
        }),
      }
    );
  },
};
