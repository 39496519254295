import { TYPES } from "./types";
export default {
  start: {
    //   title: "Olá 😁",
    //   subtitle: "Temos algumas rápidas perguntas para entender o que você precisa, tudo bem?",
    //   img: 'assets/img/initial-img.png',

    title: "Bem-vindo (a)",
    subtitle: "Faremos rápidas perguntas para te conhecer melhor!",
    img: "/assets/img/logo_widde.png",
  },
  finish: {
    title: "Obrigado!",
    subtitle:
      "Recebemos suas resposta e entraremos em contato com você em breve.\n\n\n01101000 01110100 01110100 01110000 01110011 00111010 00101111 00101111 01101000 01101001 01110010 01101001 01101110 01100111 00101101 01100100 01100101 01110110 00101101 01100001 01100100 01110110 01100001 01101110 01100011 01100101 01100100 00101110 01110111 01101001 01100100 01100100 01100101 00101110 01101001 01101111 00101111",
    // img: "assets/img/finish.png",
    // img: "/assets/img/logo_widde.png",
    text: "",
  },
  questions: [
    {
      id: "nome",
      type: TYPES.SIMPLETEXT,
      title: "Vamos começar!",
      subtitle: "Qual seu nome e sobrenome?",
    },
    {
      id: "email",
      type: TYPES.SIMPLETEXT,
      title: "✉️",
      subtitle: "Qual seu e-mail? ",
    },
    {
      id: "celular",
      type: TYPES.PHONENUMBER,
      title: "📱",
      subtitle: "Qual seu celular?",
    },
    {
      id: "idade",
      type: TYPES.AGE,
      title: "🥳",
      subtitle: "Qual sua idade?",
    },
    {
      id: "experiencias",
      type: TYPES.MULTOPTIONS,
      title: "👨🏻‍💻",
      subtitle:
        "Você já teve experiências profissionais na área de tecnologia?",
      options: [
        "Não, nunca tive",
        "Não, mas já tive em outra área",
        "Sim, experiência de até 1 ano",
        "Sim, experiência de 2 a 3 anos",
        "Sim, experiência de 4 anos ou +",
      ],
    },
    {
      id: "faculdade",
      type: TYPES.MULTOPTIONS,
      title: "📚",
      subtitle: "Você faz/fez faculdade na área?",

      options: [
        "Não, nunca fiz nada",
        "Sim, estou cursando faculdade 🤓",
        "Sim, me formei 👨🏽‍🎓",
        "Sim, mas não terminei 🏃🏼‍♀️",
      ],
    },
    {
      id: "tecnologias-onde-aprendeu",
      type: TYPES.SIMPLETEXT,
      title: "👩🏻‍💻",
      subtitle: "Onde você aprendeu o que sabe sobre tecnologia?",
    },
    {
      id: "tecnologias",
      type: TYPES.TEXT,
      minLength: 5,
      title: "👩🏻‍💻",
      subtitle: "Quais tecnologias você gosta e mais domina?",
    },
    {
      id: "sobre-voce",
      type: TYPES.TEXT,
      minLength: 10,
      title: "👇",
      subtitle: "Nos conte um pouco sobre você:",
    },

    {
      id: "redes-sociais",
      type: TYPES.SOCIALNETWORKS,
      title: "👇",
      subtitle: "Quer deixar alguma rede social?",
    },

    {
      id: "escolha-1",
      type: TYPES.MULTIMGS,
      title: "Agora é jogo rápido!",
      subtitle: "Escolhe qual?",
      options: ["/assets/img/facebook.png", "/assets/img/spacex.png"],
    },
    {
      id: "escolha-2",
      type: TYPES.MULTIMGS,
      title: "Agora é jogo rápido!",
      subtitle: "Escolhe qual?",
      options: ["/assets/img/airbnb.png", "/assets/img/itau.png"],
    },
    {
      id: "escolha-3",
      type: TYPES.MULTIMGS,
      title: "Agora é jogo rápido!",
      subtitle: "Escolhe qual?",
      options: ["/assets/img/sport.png", "/assets/img/videogame.png"],
    },
    {
      id: "sugestao",
      type: TYPES.TEXT,
      minLength: 0,
      title: "👩📝",
      subtitle: "Gostou do nosso quiz? tem alguma sugestão?",
      btnText: "Finalizar",
    },
  ],

  onFinish: (user) => {
    const columns = [
      "id",
      "deviceToken",
      "system",
      "nome",
      "email",
      "celular",
      "idade",
      "experiencias",
      "faculdade",
      "tecnologias-onde-aprendeu",
      "tecnologias",
      "sobre-voce",
      "Github",
      "Linkedin",
      "Outra",
      "escolha-1",
      "escolha-2",
      "escolha-3",
      "sugestao",
    ];
    let _user = {
      ...user,
      ...(user && user.questions ? user.questions : {}),
    };
    let text = "";
    text += "**NOVA INSCRIÇÃO**\n\n\n";
    text += columns.map((c) => `**${c}**: ${_user[c]}`).join("\n");

    fetch(
      "https://discordapp.com/api/webhooks/802327012945166377/ZXArrH2aO9ap1VAesTuk89e9fcQvjxg9k1LwToNEtViKhq7i-m3dsetRbbiQ0OWzc7dj",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: "Contratação",
          avatar_url: "",
          content: text,
        }),
      }
    );
  },
};
