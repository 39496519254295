import React from "react";
import { useDispatch } from "react-redux";
import { asnwerQuestion } from "../../redux/user";

export default ({ options, id, answer, goNext }) => {
  const dispatch = useDispatch();

  return (
    <div
      className="d-flex flex-fill  align-itens-center justify-content-center"
      style={{ height: "100%", paddingBottom: "32px" }}
    >
      <div
        className="d-flex flex-fill flex-column align-itens-center justify-content-lg-center  mw-lg-80"
        style={{ height: "100%", padding: 4 }}
      >
        {options.map((value, index) => (
          <div
            key={value}
            onClick={() => {
              dispatch(asnwerQuestion(id, value));
              goNext();
            }}
            className="d-flex w-100 gender-box "
            style={{
              fontSize: "16px",
              padding: "12px",
              fontWeight: answer == value ? "bold" : "normal",
              background: answer == value ? "#ffc000" : "#ffffff",
              marginTop: index == 0 ? "0px" : "8px",
            }}
          >
            {value}
          </div>
        ))}
      </div>
    </div>
  );
};
