import React, { Component, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { FINISH, START } from "../../config";
import { getUser, handleFinish } from "../../redux/user";
import Instructions from "../Instructions";

export default () => {
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(handleFinish());
  });
  return (
    <Instructions
      {...FINISH}
      // Obrigado, informações recebidas!

      // Logo entrarei em contato 🙏🏼🐾"
      // btnClick={() => history.push("/question/" + QUESTIONS[0].id)}
      // // btnClick={() => this.props.dispatch(setStatus(status.PLAYER_INFO_GENDER))}
      // btn={"Sim, quero!"}
    />
  );
};
