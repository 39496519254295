import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { asnwerQuestion } from "../../redux/user";

export default ({ id, answer, goNext }) => {
  const dispatch = useDispatch();
  const options = ["/assets/img/calendar.svg", "/assets/img/hi.svg"];
  const values = ["Agendar reunião", "Entrem em contato comigo"];
  const actions = ["schedule", "finish"];

  // useEffect(() => {
  //   alert(1);
  // }, [id]);
  const hasAnswer = answer && answer !== "";
  return (
    <div
      className="h-100 d-flex justify-content-center align-items-center flex-column flex-lg-row"
      style={{
        width: window.innerWidth - 72,
        //   alignItems: "center",
        //   justifyContent: "center",
        //   display: "flex",
        //   // flex: 1,
      }}
      key={id}
    >
      {/* <pre>{JSON.stringify(options)}</pre> */}
      {/* <div
        className="d-flex flex-column flex-lg-row h-100 flex-1 align-items-center justify-content-lg-center"
        style={{ maxWidth: "80%", paddingBottom: "16px" }}
      > */}
      {options.map((value, index) => (
        <div
          // href={actions[index]}
          onClick={() => {
            dispatch(asnwerQuestion(id, value));
            goNext(actions[index]);
          }}
          className="d-flex gender-box flex-column align-items-center h-sm-45 "
          style={{
            fontSize: "16px",
            padding: "16px",
            marginBottom: "16px",
            // height: "%",
            maxWidth: 250,
            // border:
            //   hasAnswer && answer == value ? `solid 1.5px #363636` : "none",
            fontWeight: answer == value ? "bold" : "normal",
            background: answer == value ? "#ffc000" : "#ffffff",
            borderRadius: "10px",
          }}
        >
          <div
            className="flex-1 w-100 text-center"
            src={value}
            style={{
              maxHeight: "calc(100% - 32px)",
              maxWidth: "100%",
            }}
          >
            <img
              src={value}
              style={{
                // maxHeight: "100%",
                maxHeight: 200,
                maxWidth: "100%",
                objectFit: "cover",
              }}
            />
          </div>
          <div
            style={{ marginTop: 8, fontSize: 18 }}
            className="w-100 text-center"
          >
            {values[index]}
          </div>
        </div>
      ))}
    </div>
  );
};
