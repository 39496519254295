import React, { useEffect, Component } from "react";
import "./App.css";
import { connect, useDispatch } from "react-redux";
import Start from "./components/Start/Start";
import Finish from "./components/Start/Finish";
import { Switch, Route } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import Questions from "./components/Questions";
import { getUser } from "./redux/user";
import { ON_START } from "./config";

Sentry.init({
  dsn: "https://bd9043ed413542c3a1975b1c20331017@sentry.io/5186794",
});

// https://calendly.com/zeero-tech/conversa-inicial/?name={{name}}&email={{email}}

export default () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUser());
  }, []);

  useEffect(() => {
    ON_START();
  }, []);
  var ua = navigator.userAgent;
  const isMobile = [
    /iPad/.test(ua),
    /iPhone/.test(ua),
    /Android 4/.test(ua),
  ].reduce((a, b) => a || b, false);

  return (
    <div
      className="d-flex flex-fill"
      style={{
        height: isMobile ? window.innerHeight + "px" : "100vh",
        padding: "16px",
      }}
    >
      <div className="d-flex flex-fill h-100 w-100 app-box">
        <Switch>
          <Route exact path="/" component={Start} />
          <Route exact path="/finish" component={Finish} />
          <Route exact path="/question/:id" component={Questions} />
          {/* component={() => (
                <>
                  {currentStatus == "" && <Start />}
                  {(currentStatus == status.PLAYER_INFO_AGE ||
                    currentStatus == status.PLAYER_INFO_GENDER) && <User />}
                  {currentStatus == status.PLAYER_STYLES && <UserStyle />}
                  {currentStatus == status.INSTRUCTION_FACE_READER && (
                    <FaceReaderInstructions />
                  )}
                </>
              )}
            /> */}
        </Switch>

        {/* <FaceReader />*/}
      </div>
    </div>
  );
};
