import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { asnwerQuestion } from "../../redux/user";

export default (props) => {
  const { id, answer, goNext, minLength = 3, hidden } = props;
  const dispatch = useDispatch();
  const [value, setValue] = useState(answer || "");

  useEffect(() => {
    setValue(props.answer || "");
  }, [props]);
  const minWidth = minLength;
  const go = (value) => {
    if (value.length < minWidth) return;
    dispatch(asnwerQuestion(id, value));
    goNext();
  };

  return (
    <div className="d-flex flex-column w-100 h-100 align-items-center justify-content-center">
      <input
        className={`input-simple-text  ${hidden && "hidden-input"}`}
        value={value}
        autoFocus
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            go(value);
          }
        }}
      />
      {hidden && (
        <input
          type="hidden"
          value="É só tirar a classe hidde-input do input aqui de cima"
        />
      )}
      <div
        className=" text-center button"
        onClick={() => go(value)}
        style={{
          cursor: "pointer",
          borderRadius: "8px",
          marginTop: "8px",
          padding: "16px",
          fontSize: "18px",
          textAlign: "center",
          opacity: value.length < minWidth ? 0.5 : 1,
        }}
      >
        Continuar
      </div>
    </div>
  );
};
