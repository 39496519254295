import { DB_NAME, ON_FINISH } from "../../config";
import { db } from "../../db";

export function getSystemInfo() {
  var module = {
    options: [],
    header: [
      navigator.platform,
      navigator.userAgent,
      navigator.appVersion,
      navigator.vendor,
      window.opera,
    ],
    dataos: [
      { name: "Windows Phone", value: "Windows Phone", version: "OS" },
      { name: "Windows", value: "Win", version: "NT" },
      { name: "iPhone", value: "iPhone", version: "OS" },
      { name: "iPad", value: "iPad", version: "OS" },
      { name: "Kindle", value: "Silk", version: "Silk" },
      { name: "Android", value: "Android", version: "Android" },
      { name: "PlayBook", value: "PlayBook", version: "OS" },
      { name: "BlackBerry", value: "BlackBerry", version: "/" },
      { name: "Macintosh", value: "Mac", version: "OS X" },
      { name: "Linux", value: "Linux", version: "rv" },
      { name: "Palm", value: "Palm", version: "PalmOS" },
    ],
    databrowser: [
      { name: "Chrome", value: "Chrome", version: "Chrome" },
      { name: "Firefox", value: "Firefox", version: "Firefox" },
      { name: "Safari", value: "Safari", version: "Version" },
      { name: "Internet Explorer", value: "MSIE", version: "MSIE" },
      { name: "Opera", value: "Opera", version: "Opera" },
      { name: "BlackBerry", value: "CLDC", version: "CLDC" },
      { name: "Mozilla", value: "Mozilla", version: "Mozilla" },
    ],
    init: function () {
      var agent = this.header.join(" "),
        os = this.matchItem(agent, this.dataos),
        browser = this.matchItem(agent, this.databrowser);

      return { os: os, browser: browser };
    },
    matchItem: function (string, data) {
      var i = 0,
        j = 0,
        html = "",
        regex,
        regexv,
        match,
        matches,
        version;

      for (i = 0; i < data.length; i += 1) {
        regex = new RegExp(data[i].value, "i");
        match = regex.test(string);
        if (match) {
          regexv = new RegExp(data[i].version + "[- /:;]([\\d._]+)", "i");
          matches = string.match(regexv);
          version = "";
          if (matches) {
            if (matches[1]) {
              matches = matches[1];
            }
          }
          if (matches) {
            matches = matches.split(/[._]+/);
            for (j = 0; j < matches.length; j += 1) {
              if (j === 0) {
                version += matches[j] + ".";
              } else {
                version += matches[j];
              }
            }
          } else {
            version = "0";
          }
          return {
            name: data[i].name,
            version: parseFloat(version),
          };
        }
      }
      return { name: "unknown", version: 0 };
    },
  };

  var e = module.init();

  return [e.os.name, e.os.version, e.browser.name, e.browser.version];
}

let guid = () => {
  let s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };
  //return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
  return (
    s4() +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    s4() +
    s4()
  );
};

export function setUser(user) {
  return {
    type: "SET_USER",
    user,
  };
}
export function asnwerQuestion(id, value) {
  return (dispatch, getState) => {
    dispatch({
      type: "ASNWER_QUESTION",
      value,
      id,
    });
  };
}

export function handleFinish() {
  return (dispatch, getState) => {
    localStorage.removeItem("token");
    const { user } = getState();
    if (ON_FINISH) ON_FINISH(user);
    dispatch(finish());
  };
}
export function finish() {
  return {
    type: "FINISH",
  };
}

export function getUser() {
  return async (dispatch) => {
    let token = localStorage.getItem("token");
    let deviceToken = localStorage.getItem("deviceToken");
    if (!deviceToken) {
      deviceToken = guid();
      localStorage.setItem("deviceToken", deviceToken);
    }

    if (!token) {
      // create user
      token = guid();
      localStorage.setItem("token", token);
      const newUser = {
        id: token,
        deviceToken,
        questions: {},
        system: getSystemInfo(),
      };
      dispatch({
        type: "SET_USER",
        user: newUser,
      });
    } else {
      //   debugger;
      const _user = (await db.collection(DB_NAME).doc(token).get()).data();
      console.log("_user", _user);
      //   deblugger;
      if (_user) dispatch(setUser(_user));
      else {
        const newUser = {
          id: token,
          deviceToken,
          questions: {},
          system: getSystemInfo(),
        };
        dispatch({
          type: "SET_USER",
          user: newUser,
        });
      }
      // TO.DO load user from database
    }
  };
}

export default function user(state = {}, action) {
  switch (action.type) {
    case "SET_USER":
      return action.user;
    case "ASNWER_QUESTION":
      return {
        ...state,
        questions: {
          ...state.questions,
          [action.id]: action.value,
        },
      };
    case "FINISH":
      return {};
    default:
      return state;
  }
}
