export const TYPES = {
  OBJECTIVE: "OBJECTIVE",
  AGE: "AGE",
  TEXT: "Text",
  SIMPLETEXT: "SimpleText",
  PHONENUMBER: "PHONENUMBER",
  MULTOPTIONS: "MULT-OPTIONS",
  MULTIMGS: "MULT-IMGS",
  CONTACT: "CONTACT",
  SOCIALNETWORKS: "SOCIALNETWORKS",
};
