import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import { DB_NAME } from "../config";
import { db } from "../db";
import user from "./user";

const save = (store) => (next) => (action) => {
  const result = next(action);
  const { user } = store.getState();
  if (user.id) db.collection(DB_NAME).doc(user.id).set(user);

  return result;
};
export default createStore(
  combineReducers({
    user,
  }),
  applyMiddleware(thunk, save)
);
