import React from "react";

export default function Step({ step, max }) {
  const steps = [];

  for (let i = 0; i < max; i++) steps.push(i);

  return (
    <div
      className="d-flex w-100"
      style={{
        marginTop: "16px",
        height: "2px",
        background: "#e4e4e5",
        borderRadius: "2px",
      }}
    >
      {steps.map((s, index) => (
        <div
          key={index}
          className={`d-flex flex-fill h-100  ${
            index + 1 == step && "progress-active"
          }`}
          style={{
            borderRadius: "2px",
          }}
        ></div>
      ))}
    </div>
  );
}
