import React from "react";
import { useDispatch } from "react-redux";
import { asnwerQuestion } from "../../redux/user";

export default ({ options, id, answer, goNext }) => {
  const dispatch = useDispatch();

  return (
    <div className="d-flex flex-column w-100">
      {options.map((op, i) => (
        <div
          key={i}
          className="d-flex flex-row w-100"
          style={{ marginTop: i == 0 ? "0px" : "24px" }}
        >
          {op.map((o, index) => (
            <div
              key={index}
              onClick={() => {
                dispatch(asnwerQuestion(id, o));
                goNext();
              }}
              className="d-flex  gender-box justify-content-center align-items-center"
              style={{
                padding: "20px",
                flex: 1,
                height: "96px",
                marginRight: index == 0 ? "24px" : "0px",
                background: answer == o ? "#ffc000" : "#ffffff",
                fontSize: "20px",
              }}
            >
              {o}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
