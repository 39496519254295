import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/analytics";

var firebaseConfig = {
  apiKey: "AIzaSyDtFcpJd3CHOKSqwmrGDq4GPWtb-0mb3rQ",
  authDomain: "hiring-9f29f.firebaseapp.com",
  projectId: "hiring-9f29f",
  storageBucket: "hiring-9f29f.appspot.com",
  messagingSenderId: "428049956061",
  appId: "1:428049956061:web:41fcb4d519ca2d5e2f2f34",
  measurementId: "G-BBYCJ1XJ41"
};

const app = firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();
firebase.analytics();
export default app;
