import { TYPES } from "./types";

export default {
  start: {
    title: "CASA DO VIRA LATA",
    textAlign: "left",
    subtitle: `Todo animal merece viver o privilégio da adoção, ter morada definitiva, amor, carinho, alimento todo dia e uma cama quentinha para dormir em paz.
      Resgato animais na rua e busco dar à eles a chance de viverem o privilégio da adoção. Levo até você pela capital de SP.
      A adoção deve ser feita sempre com responsabilidade! Por isso, preparei algumas perguntas para te conhecer um pouquinho. Entrarei em contato com você em seguida :)`,
    img: "assets/img/casadoviralata.jpg",
  },
  finish: {
    title: "Obrigado!",
    subtitle: `Obrigado, informações recebidas!  

      Logo entrarei em contato 🙏🏼🐾`,
    img: "assets/img/casadoviralata.jpg",
  },
  questions: [
    {
      id: "nome",
      type: TYPES.SIMPLETEXT,
      title: "Vamos começar!",
      subtitle: "Qual o seu nome e sobrenome?",
    },
    {
      id: "celular",
      type: TYPES.PHONENUMBER,
      title: "📱",
      subtitle: "Seu celular para contato",
    },
    {
      id: "email",
      type: TYPES.SIMPLETEXT,
      title: "✉️",
      subtitle: "Seu e-mail para contato",
    },
    {
      id: "cidade",
      type: TYPES.SIMPLETEXT,
      title: "📍",
      subtitle: "Qual cidade você mora?",
    },
    {
      id: "bairro",
      type: TYPES.SIMPLETEXT,
      title: "📍",
      subtitle: "E o bairro?",
    },
    {
      id: "especie",
      type: TYPES.MULTOPTIONS,
      title: "🐶😻",
      subtitle: "Espécie interessada",
      options: ["Canina", "Felina", "Ambas"],
    },

    {
      id: "interesse",
      type: TYPES.SIMPLETEXT,
      minLength: 0,
      title: "🤓📝",
      subtitle:
        "Tem mais interesse por algum dos que estão para adoção? Se sim, qual o nome?",
    },
    {
      id: "pessoas",
      type: TYPES.MULTOPTIONS,
      title: "🏠",
      subtitle: "Quantas pessoas moram com você?",
      options: ["1", "2", "3", "4", "5 ou mais"],
    },

    {
      id: "acordo",
      type: TYPES.MULTOPTIONS,
      title: "🤓📝",
      subtitle: "Todos que moram com você estão se acordo com a adoção?",
      options: ["Sim", "Não"],
    },
    {
      id: "criancas",
      type: TYPES.MULTOPTIONS,
      title: "👶🏽",
      subtitle: "Quantas crianças moram com você ou te visitam com frequência?",
      options: ["0", "1", "2", "3", "4", "5 ou mais"],
    },
    {
      id: "alergias",
      type: TYPES.MULTOPTIONS,
      title: "🤒🤧",
      subtitle:
        "Há alguém que terá contato com o animal com alergias/rinite ou problemas respiratórios?",
      options: ["Sim", "Não"],
    },
    {
      id: "moradia",
      type: TYPES.MULTOPTIONS,
      title: "🏠",
      subtitle: "Você mora em:",
      options: ["Casa", "Apartamento", "Outro"],
    },
    {
      id: "residencia",
      type: TYPES.MULTOPTIONS,
      title: "🏠",
      subtitle: "Sua residência é:",
      options: ["Própria", "Alugada", "Outro"],
    },

    {
      id: "restricao",
      type: TYPES.MULTOPTIONS,
      title: "🏠",
      subtitle: "Existe alguma restrição para animais na residência?",
      options: ["Sim", "Não"],
    },

    {
      id: "animais",
      type: TYPES.MULTOPTIONS,
      title: "🐶😺",
      subtitle: "Você já tem algum animal na sua residência?",
      options: ["Sim", "Não"],
    },
    {
      id: "acesso",
      type: TYPES.TEXT,
      title: "🏠",
      subtitle:
        "O animal terá acesso a todos os ambientes da residência ou terá locais determinados (comente brevemente)?",
    },
    {
      id: "telas",
      type: TYPES.MULTOPTIONS,
      title: "🏠",
      subtitle: "Possui janela com telas?",
      options: ["Sim", "Não"],
    },
    {
      id: "quintal",
      type: TYPES.MULTOPTIONS,
      title: "🏠",
      subtitle: "Possui quintal? ",
      options: ["Sim", "Não"],
    },
    {
      id: "seguro-contra-fugas",
      type: TYPES.MULTOPTIONS,
      title: "🛣️",
      subtitle: "O acesso à rua é seguro contra fugas?",
      options: ["Sim", "Não"],
    },
    {
      id: "animal-sozinho",
      type: TYPES.MULTOPTIONS,
      title: "⌛",
      subtitle: "Quantas horas por dia o animal ficará sozinho?",
      options: ["0", "1 ou 2", "3 ou 4", "5, 6 ou 7", "8 ou mais"],
    },
    {
      id: "viajar",
      type: TYPES.MULTOPTIONS,
      title: "⛱️",
      subtitle: "Caso você viaje, onde o animal ficará?",
      options: [
        "Viajará sempre comigo",
        "Em hotelzinho",
        "Em casa com cuidadores",
        "Em casa de algum parente ou amigo",
      ],
    },
    {
      id: "custear",
      type: TYPES.MULTOPTIONS,
      title: "🏥🩺",
      subtitle:
        "Você tem condições de custear veterinário, cuidados e necessidades do animal?",
      options: ["Sim", "Não"],
    },
    {
      id: "casadoviralata",
      type: TYPES.MULTOPTIONS,
      title: "🙌",
      subtitle: "Por onde ficou sabendo da Casa do Vira Lata? ",
      options: ["Instagram", "Facebook", "Google", "Indicação", "Outro"],
    },
    {
      id: "instagram",
      type: TYPES.SIMPLETEXT,
      minLength: 0,
      title: "💻",
      subtitle: "Se tiver Instagram, coloque seu @ aqui?",
    },
  ],
};
