import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { asnwerQuestion } from "../../redux/user";

export default (props) => {
  const {
    id,
    answer,
    goNext,
    minLength = 0,
    btnText = "Continuar",
    hidden,
  } = props;
  const dispatch = useDispatch();
  const [value, setValue] = useState(answer || "");
  const go = (value) => {
    if (value.length < minLength) return;
    dispatch(asnwerQuestion(id, value));
    goNext();
  };
  useEffect(() => {
    setValue(props.answer || "");
  }, [props]);

  return (
    <div className="d-flex flex-column w-100 h-100 align-items-center justify-content-center">
      <textarea
        value={value}
        autoFocus
        onChange={(e) => setValue(e.target.value)}
      ></textarea>
      <div
        className={` text-center button`}
        onClick={() => go(value)}
        style={{
          cursor: "pointer",
          borderRadius: "8px",
          marginTop: "8px",
          padding: "16px",
          fontSize: "18px",
          textAlign: "center",
          opacity: value.length < minLength ? 0.5 : 1,
        }}
      >
        {btnText}
      </div>
    </div>
  );
};
