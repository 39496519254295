import contact from "./contact";
import hiring from "./hiring";
import hiring2 from "./hiring-dev-advanced";
import inscricaobeta from "./inscricao-beta";
import casadoviralata from "./casadoviralata";
export { TYPES } from "./types";
const allDbs = {
  contact,
  hiring,
  casadoviralata,
  ["inscricao-beta"]: inscricaobeta,
  ["hiring-dev-advanced"]: hiring2,
};

const defaultDb = allDbs["hiring"];
const currentDb = window.location.host.split(".")[1]
  ? window.location.host.split(".")[0]
  : false;
  
export const QUESTIONS = (allDbs[currentDb] || defaultDb).questions;
export const ON_FINISH =
  (allDbs[currentDb] || defaultDb).onFinish || (() => false);
export const ON_START =
  (allDbs[currentDb] || defaultDb).onStart || (() => false);
export const START = (allDbs[currentDb] || defaultDb).start;
export const FINISH = (allDbs[currentDb] || defaultDb).finish;
export const DB_NAME = currentDb || "defaultDb2";
