import React, { Component } from "react";
export default function Instructions(props) {
  return (
    <div
      className="d-flex flex-fill flex-column align-items-center w-100"
      style={{ padding: "20px", paddingTop: "24px", paddingBottom: "24px" }}
    >
      <div
        className="d-flex text-center heading"
        style={{
          fontSize: "32px",
          paddingTop: "24px",
          fontWeight: "bold",
          lineHeight: 1.33,
        }}
      >
        {props.title}
      </div>
      <div
        className="d-flex flex-column texting"
        style={{
          fontSize: "18px",
          paddingTop: "24px",
          width: "85%",
          lineHeight: 1.5,
          overflow: "auto",
          textAlign: !props.textAlign ? "center" : props.textAlign,
        }}
      >
        {props.subtitle.split("\n").map((n, index) => (
          <p key={index}>{n}</p>
        ))}
      </div>
      <div
        className="d-flex w-100 flex-fill flex-column align-items-center justify-content-center img-instruction"
        style={{
          background: `url('${props.img}') center center no-repeat`,
          backgroundSize: "auto auto",
          maxWidth: 400,
        }}
      >
        {/* <img src={props.img} style={{ fontSize: '32px', maxWidth: 'calc(100% - 16px)', maxHeight: 'calc(100% - 32px)', width: 'auto'}}  /> */}
      </div>
      {props.btn && (
        <div
          className="d-flex text-center button"
          onClick={() => props.btnClick()}
          style={{
            cursor: "pointer",
            borderRadius: "8px",
            padding: "16px",
            fontSize: "18px",
          }}
        >
          {props.btn}
        </div>
      )}
    </div>
  );
}
