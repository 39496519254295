import { TYPES } from "./types";
export default {
  start: {
    //   title: "Olá 😁",
    //   subtitle: "Temos algumas rápidas perguntas para entender o que você precisa, tudo bem?",
    //   img: 'assets/img/initial-img.png',

    title: "Bem-vindo (a)",
    subtitle: "Que bom te ver aqui. Bora responder mais algumas perguntas?",
    img: "/assets/img/logo_widde_2.png",
  },
  finish: {
    title: "Obrigado!",
    subtitle:
      "Recebemos suas resposta e entraremos em contato com você em breve.",
    // img: "assets/img/finish.png",
    img: "/assets/img/logo_widde_2.png",
  },
  questions: [
    {
      id: "email",
      type: TYPES.SIMPLETEXT,
      title: "👨🏻‍💻",
      subtitle: "Qual seu e-mail? ",
    },
    {
      id: "origin",
      type: TYPES.TEXT,
      title: "👨🏻‍💻",
      subtitle: "Como chegou até aqui?",
    },
    {
      id: "gostos",
      type: TYPES.TEXT,
      title: "👨🏻‍💻",
      subtitle: "O que você mais gosta de fazer em tech?",
    },
    {
      id: "momento",
      type: TYPES.TEXT,
      title: "👨🏻‍💻",
      subtitle: "Qual maior desafio você já enfrentou?",
    },
    {
      id: "futuro",
      type: TYPES.TEXT,
      title: "👨🏻‍💻",
      subtitle: "Onde você quer estar em 10 anos?",
    },
    {
      id: "inspect",
      type: TYPES.SIMPLETEXT,
      hidden: true,
      title: "Melhor usar um 💻",
      subtitle: "Sabe dar inspect no código?",
    },
    {
      id: "e-obvio-que-eu-sei",
      type: TYPES.SIMPLETEXT,
      title: "👨🏻‍💻",
      subtitle: "E olhar o path do route?",
    },
  ],

  onStart: () => {
    document.body.className += " dark ";
  },
  onFinish: (user) => {
    const columns = [
      "email",
      "origin",
      "gostos",
      "momento",
      "futuro",
      "inspect",
      "e-obvio-que-eu-sei",
    ];
    let _user = {
      ...user,
      ...(user && user.questions ? user.questions : {}),
    };
    let text = "";
    text += "**NOVA INSCRIÇÃO**\n\n\n";
    text += columns.map((c) => `**${c}**: ${_user[c]}`).join("\n");

    fetch(
      "https://discordapp.com/api/webhooks/802327012945166377/ZXArrH2aO9ap1VAesTuk89e9fcQvjxg9k1LwToNEtViKhq7i-m3dsetRbbiQ0OWzc7dj",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: "Contratação",
          avatar_url: "",
          content: text,
        }),
      }
    );
  },
};
