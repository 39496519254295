import React, { useCallback, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Page from "../Page";
import Objective from "./Objective";
import { useDispatch, useSelector } from "react-redux";
import Age from "./Age";
import MultText from "./MultText";
import MultImgs from "./MultImgs";
import Text from "./Text";
import SimpleText from "./SimpleText";
import Networks from "./Networks";
import PhoneNumber from "./PhoneNumber";
import Contact from "./Contact";
import { handleFinish } from "../../redux/user";
import { QUESTIONS, TYPES } from "../../config";
export default () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { id } = params;

  const question = QUESTIONS.find((q) => q.id == id);
  const questionIndex = QUESTIONS.findIndex((q) => q.id == id);
  const history = useHistory();
  const user = useSelector(({ user }) => user);

  if (!question) return <p>Não encontrado</p>;

  const components = {
    [TYPES.OBJECTIVE]: Objective,
    [TYPES.AGE]: Age,
    [TYPES.MULTOPTIONS]: MultText,
    [TYPES.MULTIMGS]: MultImgs,
    [TYPES.TEXT]: Text,
    [TYPES.SIMPLETEXT]: SimpleText,
    [TYPES.SOCIALNETWORKS]: Networks,
    [TYPES.PHONENUMBER]: PhoneNumber,
    [TYPES.CONTACT]: Contact,
  };
  const goNext = (finishInfo) => {
    const nextQuestionIndex = questionIndex + 1;
    if (nextQuestionIndex >= QUESTIONS.length) {
      if (finishInfo == "schedule") {
        let params = "n=1";
        if (user.questions) {
          if (user.questions.nome) {
            params += `&name=${user.questions.nome}`;
          }

          if (user.questions.email) {
            params += `&email=${user.questions.email}`;
          }
        }
        dispatch(handleFinish());
        return (window.location.href =
          "https://calendly.com/zeero-tech/conversa-inicial/?" + params);
      }
      return history.push(`/finish`);
    }
    history.push(`/question/${QUESTIONS[nextQuestionIndex].id}`);
  };

  const goBack = () => {
    const backQuestionIndex = questionIndex - 1;
    history.push(`/question/${QUESTIONS[backQuestionIndex].id}`);
  };

  const QuestionComp = components[question.type];

  return (
    <Page
      numberOfQuestions={QUESTIONS.length}
      step={questionIndex + 1}
      {...question}
      back={questionIndex == 0 ? undefined : goBack}
    >
      <QuestionComp
        {...question}
        answer={(user.questions || {})[question.id]}
        goNext={goNext}
      />
    </Page>
  );
};
