import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { asnwerQuestion } from "../../redux/user";

export default ({ options: _options, id, answer, goNext, values }) => {
  const dispatch = useDispatch();

  const options = JSON.parse(JSON.stringify(_options));
  // useEffect(() => {
  //   alert(1);
  // }, [id]);
  const hasAnswer = answer && answer !== "";

  if (!values)
    return (
      <div
        className="d-flex flex-fill justify-content-center h-100 align-items-center"
        style={{ paddingBottom: "32px" }}
      >
        {options.map((value, index) => (
          <div
            key={value}
            onClick={() => {
              dispatch(asnwerQuestion(id, value));
              goNext();
            }}
            className="d-flex h-100 flex-fill flex-column align-items-center justify-content-center img-instruction"
            style={{
              marginRight: index == 0 ? "24px" : "0px",
              background: `url('${value}') center center no-repeat`,
              backgroundSize: "auto auto",
              // border:
              //   hasAnswer && answer == value ? `solid 1.5px #363636` : "none",
              opacity: hasAnswer && answer !== value ? 0.5 : 1,
              borderRadius: "10px",
            }}
          ></div>
        ))}
      </div>
    );
  return (
    <div
      style={{
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flex: 1,
      }}
      key={id}
    >
      {/* <pre>{JSON.stringify(options)}</pre> */}
      <div
        className="d-flex flex-column flex-lg-row h-100 flex-1 align-items-center justify-content-lg-center"
        style={{ maxWidth: "100%", paddingBottom: "16px" }}
      >
        {options.map((value, index) => (
          <div
            key={id + value}
            onClick={() => {
              dispatch(asnwerQuestion(id, value));
              goNext();
            }}
            className="dlex flx-1 gender-box flex-column align-items-center "
            style={{
              fontSize: "16px",
              padding: "16px",
              marginBottom: "16px",
              marginRight: "16px",
              marginLeft: "16px",
              maxWidth: 350,

              // border:
              //   hasAnswer && answer == value ? `solid 1.5px #363636` : "none",
              fontWeight: answer == value ? "bold" : "normal",
              background: answer == value ? "#ffc000" : "#ffffff",
              borderRadius: "10px",
            }}
          >
            <img
              src={value}
              style={{
                maxWidth: "100%",
                maxHeight: 200,
                objectFit: "cover",
                verticalAlign: "bottom",
              }}
            />
            <div style={{ marginTop: 8, fontSize: 18 }} className="text-center">
              {values[index]}
            </div>
          </div>
        ))}

        <div className={"d-lg-none w-100"} style={{ minHeight: 8 }}></div>
      </div>
    </div>
  );
};
