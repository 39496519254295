import React, { Component, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ON_START, QUESTIONS } from "../../config";
import { getUser } from "../../redux/user";
import { START } from "../../config/index";
import Instructions from "../Instructions";

export default () => {
  const history = useHistory();

  return (
    <Instructions
      {...START}
      btnClick={() => history.push("/question/" + QUESTIONS[0].id)}
      // btnClick={() => this.props.dispatch(setStatus(status.PLAYER_INFO_GENDER))}
      btn={"Começar!"}
    />
  );
};
