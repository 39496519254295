import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { asnwerQuestion } from "../../redux/user";

export default ({ id, answer, goNext }) => {
  const dispatch = useDispatch();
  const [age, setAge] = useState(answer || "");

  const go = (value) => {
    if (value.length !== 2) return;
    dispatch(asnwerQuestion(id, value));
    goNext();
  };

  return (
    <div className="d-flex flex-column w-100 h-100 align-items-center justify-content-center">
      <div className="age-input d-flex flex-row">
        <input
          value={age}
          autoFocus
          className="age-input-inpt"
          onChange={(e) => setAge(e.target.value)}
          maxLength={2}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              go(age);
            }
          }}
        />
        <div
          className="age-input-btn"
          onClick={() => go(age)}
          style={{
            opacity: age.length < 2 ? 0.5 : 1,
          }}
        >
          <img src="/assets/img/arrow.png" />
        </div>
      </div>
    </div>
  );
};
