import { TYPES } from "./types";

export default {
  start: {
    title: "Olá 😁",
    subtitle:
      "Temos algumas rápidas perguntas para entender o que você precisa, tudo bem?",
    img: "assets/img/initial-img.png",
  },
  finish: {
    title: "Obrigado!",
    subtitle:
      "Recebemos suas resposta e entraremos em contato com você em breve.",
    img: "assets/img/finish.png",
  },
  questions: [
    {
      id: "nome",
      type: TYPES.SIMPLETEXT,
      title: "Vamos começar!",
      subtitle: "Qual seu nome e sobrenome?",
    },
    {
      id: "email",
      type: TYPES.SIMPLETEXT,
      title: "✉️",
      subtitle: "Qual seu e-mail? ",
    },
    {
      id: "celular",
      type: TYPES.PHONENUMBER,
      title: "📱",
      subtitle: "Qual seu celular?",
    },
    {
      id: "app",
      type: TYPES.MULTIMGS,
      title: "👨🏻‍💻",
      subtitle: "O que você precisa: ",
      // options: [["/"]],
      options: [
        "/assets/img/app.svg",
        "/assets/img/web.svg",
        "/assets/img/web-app.svg",
      ],
      values: ["Aplicativo", "Sistma Web", "Aplicativo + Web"],
      // options: [["A", "B"]],
    },
    {
      id: "admin",
      type: TYPES.MULTIMGS,
      title: "💻",
      subtitle: "Você necessita de um painel administrativo pro seu sistema?",
      // options: [["/"]],
      options: [
        "/assets/img/painel-adm.svg",
        "/assets/img/sem-painel.svg",
        "/assets/img/painel-talvez.svg",
      ],
      values: ["Sim", "Não", "Talvez"],
      // options: [["A", "B"]],
    },
    {
      id: "sobre-o-projeto",
      type: TYPES.MULTOPTIONS,
      minLength: 0,
      title: "👩📝",
      subtitle: "Em que estágio se encontra o seu projeto?",
      options: [
        "É apenas um projeto",
        "Já tenho um esboço ou Design preparado",
        "Está em desenvolvimento",
        "Já está pronto",
      ],
    },
    {
      id: "contato",
      type: TYPES.CONTACT,
      title: "🗓️",
      subtitle: "E agora, como você prefere?",
      // options: [["/"]],
      // hiddenNextB
      // btnText: "Finalizar",
    },
  ],
};
